<template>
    <modal ref="modalProveedoresImportantes" icon="box" no-aceptar no-cerrar tamano="modal-lg" titulo="Proveedores más importantes por compras realizadas">
        <div class="row mx-0">
            <div class="col-auto text-general">
                <b>Periodo:</b> {{ fecha }}
            </div>
            <div class="col-auto d-middle ml-auto">
                <div class="bg-general2 br-5" style="height:18px;width:18px;" />
                <span class="mx-2 text-general f-14">
                    Abastecimiento
                </span>
            </div>
            <div class="col-auto d-middle">
                <div class="bg-light-f5 br-5" style="height:18px;width:18px;" />
                <span class="mx-2 text-general f-14">
                    Retiros
                </span>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0">
            <el-table
            stripe
            :data="tableData"
            style="width: 100%"
            >
                <el-table-column
                align="right"
                width="80"
                class-name="text-center"
                >
                    <template slot="header">
                        <i class="icon-cedis f-20 text-general" />
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.cedis }}
                    </template>
                </el-table-column>
                <el-table-column
                label="Proveedores"
                prop="nombre"
                class-name="text-general"
                />
                <el-table-column
                label="Compras"
                class-name="text-center text-general"
                >
                    <template slot-scope="{ row }">
                        <div class="row mx-0">
                            <div class="col-auto text-general2 f-15">
                                {{ separadorNumero (row.compras) }}
                            </div>
                            <div class="col px-0">
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content">
                                        <div class="row mx-0 my-2">
                                            <div class="bg-general2 br-5" style="width:18px;height:18px;" />
                                            <span class="mx-2">{{ agregarSeparadoresNumero(row.porcentaje_compras_abastecimiento) }}%</span>
                                            <span class="ml-2">Abastecimiento</span>
                                        </div>
                                        <div class="row mx-0">
                                            <div class="bg-light-f5 br-5" style="width:18px;height:18px;" />
                                            <span class="mx-2">{{ agregarSeparadoresNumero(row.porcentaje_compras_retiro) }}%</span>
                                            <span class="ml-2">Retiro</span>
                                        </div>
                                    </div>
                                    <div class="col-6 px-0">
                                        <div class="row mx-0 br-5 border" style="height:18px;">
                                            <div class="h-100 bg-general2" :class="`${row.porcentaje_compras_retiro == 0 ? 'br-5' : 'br-l-5'}`" :style="`width:${porcentajesBordes(row.porcentaje_compras_abastecimiento)}%`" />
                                            <div class="h-100 bg-light-f5" :class="`${row.porcentaje_compras_abastecimiento == 0 ? 'br-5' : 'br-r-5'}`" :style="`width:${porcentajesBordes(row.porcentaje_compras_retiro)}%`" />
                                        </div>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                label="Productos comprados"
                class-name="text-center text-general"
                prop="productos"
                />
            </el-table>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            tableData: [],
            fecha: null,
        }
    },
    methods: {
        toggle(proveedores, fecha){
            this.tableData = _.cloneDeep(proveedores)
            this.fecha = fecha
            this.$refs.modalProveedoresImportantes.toggle();
        }
    }
}
</script>

